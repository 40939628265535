import React from "react"
import QuizMaster from "../../../components/layouts/quiz_master"
import Settings from "../../../components/quiz/settings"
import Toggle from "../../../components/quiz/toggle"
import Button from '@material-ui/core/Button';
import { toKatakana, toHiragana, toRomaji } from 'wanakana';

const kanaData = require("../../../components/japanese/data/kana.json")
const toggle_structure = {
  "Basic Kana":["あ-お", "か-こ", "さ-そ", "た-と", "な-の", "は-ほ", "ま-も", "や-よ", "ら-ろ", "わ-ん"],
  "Dakuten Kana":["が-ご", "ざ-ぞ", "だ-ど", "ば-ぼ", "ぱ-ぽ"],
  "Basic Combo":["きゃ-きょ", "しゃ-しょ", "ちゃ-ちょ", "にゃ-にょ", "ひゃ-ひょ", "みゃ-みょ", "りゃ-りょ"],
  "Dakuten Combo":["ぎゃ-ぎょ", "じゃ-じょ", "ぢゃ-ぢょ", "びゃ-びょ", "ぴゃ-ぴょ"]
}

class Kana extends React.Component  {
  constructor(props) {
    super(props);
    this.kana = "";
    this.lastkana = {}
    this.answer = ""
    this.correct = 0;
    this.incorrect = 0;
    this.newKana = this.newKana.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);
    this.kanaToggles = {}
    Object.entries(kanaData).forEach(([k, v]) => this.kanaToggles[k] = false);
    this.kanaToggles["あ-お"] = true;
    this.kata = false;
    this.state = {
        loaded: false,
        kana: this.word,
        lastkana: this.lastword,
        question: "",
        lastAnswer: "",
        status: "",
        wait: false,
        correct: this.correct,
        incorrect: this.incorrect,
        kanaToggles: this.kanaToggles,
        kata: this.kata,
    };
  }

  prepareToggles() {
    let settings = []
    for (const type in toggle_structure) {
      settings.push(<span style={{display:"block", marginTop:"20px"}} key={type}>{type}</span>)
      for (var group in toggle_structure[type]) {
        settings.push(React.createElement(Toggle, {
          id:toggle_structure[type][group],
          key:toggle_structure[type][group],
          label:this.state.kata ? toKatakana(toggle_structure[type][group]) : toggle_structure[type][group],
          checked:this.state.kanaToggles[toggle_structure[type][group]],
          onToggle:this.kanaToggle.bind(this, toggle_structure[type][group]),
        }))
      }
    }
    return settings;
  }

  kanaToggle = e => {
    this.kanaToggles[e] = !this.kanaToggles[e]
    if (this.getActive().length === 0) {this.kanaToggles["あ-お"] = true}
    this.setState({ kanaToggles: this.kanaToggles });
    this.newKana();
  }

  kataToggle = e => {
    this.kata = !this.kata;
    this.setState({ kata: this.kata });
    this.newKana();
  }

  toggleAll = e => {
    let inactiveToggles = this.numInactive();
    Object.keys(this.kanaToggles).forEach(k => this.kanaToggles[k] = inactiveToggles !== 0);
    this.kanaToggles["あ-お"] = true;
    this.setState({ kanaToggles: this.kanaToggles });
    this.newKana();
  }

  getActive() {
    // Returns an array of the active toggles
    return Object.keys(this.kanaToggles).filter(k => this.kanaToggles[k]);
  }

  numInactive() {
    // Returns the number of this inactive toggles
    return Object.keys(this.kanaToggles).filter(k => !this.kanaToggles[k]).length;
  }

  newKana() {
    let activeKana = this.getActive();
    let kanaGroup = activeKana[Math.floor(Math.random() * activeKana.length)]
    this.kana = QuizMaster.generateItem(kanaData[kanaGroup], this.lastkana);
    this.lastkana = this.kana;
    let status = "";
    this.setState({ kana: this.kana, lastkana: this.kana, wait: false, status: status});
    this.updateQuestion()
    this.updateAnswer()
  }

  updateQuestion() {
    let question = this.kata ? toKatakana(this.kana) : this.kana;
    this.setState({ question: question });
  }

  updateAnswer() {
    this.answer = toRomaji(this.kana)
  }

  checkAnswer(userAnswer) {
    var status = "";

    if(this.answer === toRomaji(toHiragana(userAnswer))) {
      status = "correct";
      this.correct += 1;
    } else {
      status = "incorrect";
      this.incorrect += 1;
    }
    let lastAnswer = this.answer;
    this.setState({status: status, correct: this.correct, incorrect: this.incorrect, lastAnswer: lastAnswer, wait: true})
  }

  componentDidMount() {
    if(!this.state.loaded) {
      this.setState({ loaded: true });
      this.newKana();
    }
  }

  render() {
    return (
      <QuizMaster
        title="Hiragana / Katakana"
        description="Interactive Study tool for learning Japanese Hiragana and Katakana, with options to select which characters you want to focus on."
        kanji={true}
        question={this.state.question}
        wait={this.state.wait}
        unbind={true}
        status={this.state.status}
        onSubmit={this.checkAnswer}
        onClear={this.newKana}
        noCheck={true}
        correct={this.state.correct}
        incorrect={this.state.incorrect}
        answer={this.state.lastAnswer}
        settings={
          <>
            <Settings>
              {this.prepareToggles()}
            </Settings>
            <Button variant="contained" color={this.state.kata ? "secondary" : "primary"} style={{margin:"10px"}} onClick={this.kataToggle}>{this.state.kata ? "Hiragana" : "Katakana"}</Button>
            <Button variant="contained" style={{margin:"10px"}} onClick={this.toggleAll}>
              {this.numInactive() === 0 ? "Select None" : "Select All"}
            </Button>
          </>
        }
      >   
      </QuizMaster>
    )
  }
}

export default Kana
