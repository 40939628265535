import React from "react"
import * as styles from "../styles/toggle.module.scss"

const Toggle = (props) => (
  <li className={styles.tgListItem}>
    <h4>{ props.label }{ props.wip && <sup>WIP</sup> }</h4>
    <input className={[styles.tgl, styles.tglLight].join(' ')} id={props.id} name={props.name} checked={props.checked} onChange={props.onToggle} type="checkbox"/>
    <label className={styles.tglBtn} htmlFor={props.id}></label>
  </li>
)

export default Toggle
