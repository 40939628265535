import React from "react"
import styled from "styled-components"

const Settings = styled.div`
  font-size: 14px;

  @media only screen and (max-width: 500px) {
    margin: 0 1em;
  }

  legend {
    border-bottom: none;
    color: inherit;
    font-size: 21px;
    width: 100%;
  }

  fieldset {
    display: inline;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid var(--borderInput) !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }
`

export default ({children}) => (
  <Settings id="settings">
    <fieldset>
      <legend>Settings</legend>
      {children}
    </fieldset>
  </Settings>
)
